import formatStringByPattern from  'format-string-by-pattern'
import moment from 'moment'
import _ from 'lodash';
import { round, isValueUndefined, parseExcelDate, toSearchString } from './helper'

export const INVALID_FIELD_STRING = 'Invalid field';

function formatAmount(amount, valIfZero = '0', local = 'en-CA') {
    var val = round(amount, 2)
    if (val === 0 && valIfZero !== '0') return valIfZero
    var str = val.toLocaleString(local, {currency: 'CAD', style: 'currency'})
    if (val < 0) str = '(' + str.replace('-', '') + ')'
    return str
}

function formatPercentage(value, valIfZero = '0', local = 'en-CA') {
    var val = Number(value) * 100
    return val.toLocaleString(local)
}

function formatNumber(value, precision = -1, local = 'en-CA', valIfZero = '0', valIfNaN = '') {
    var val = Number(value)
    if (isNaN(val)) return valIfNaN
    if (val === 0 && valIfZero !== '0') return valIfZero
    if (precision > -1 && val % 1 !== 0) val = val.toFixed(precision)
    return val.toLocaleString(local)
}

function formatDate(date, local = 'en-CA') {
    const dt = moment(date).utc();
    if (!date || !dt.isValid()) return '' 
    return dt.format('DD MMM YYYY')
}
  
/**
 * Format the date as "YYYY-MM-DD" (UTC)
 * @param {any} date 
 * @param {string | undefined} local The locale. Default: 'en-CA'
 * @returns {string} an empty string if the date is not valid, or the formatted date
 */
function formatDateYYYYMMDD(date, local = 'en-CA') {
    const dt = moment(date).utc();
    if (!date || !dt.isValid()) return '';
    return dt.format('YYYY-MM-DD')
}
 
/**
 * Format the data object/instance as "safe" data for Tabulator
 * 
 * Class with getter causes issues in Tabulator, see https://github.com/olifolkerd/tabulator/issues/3261
 * @param {object} instance instance data (a class instance)
 * @param {string[]} headerNames List of headers
 * @returns {object} The data as a plain object
 */
function formatTabulatorSafeDataInstance(instance, headerNames) {
    if(Array.isArray(headerNames) && headerNames.length > 0) {
        if (typeof instance === 'object'){
            const dataObject = {};
            headerNames.forEach(headerName => {
                _.set(dataObject, headerName, _.get(instance, headerName));
            });
            return dataObject;
        }
    }
    return instance;
}

/**
 * Format the data as "safe" data for Tabulator
 * 
 * Class with getter causes issues in Tabulator, see https://github.com/olifolkerd/tabulator/issues/3261
 * @param {object[]} data data (array of class instances
 * @param {string[]} headerNames List of headers
 * @returns {object[]} The data as an array of plain objects
 */
function formatTabulatorSafeData(data, headerNames) {
    if(Array.isArray(headerNames) && headerNames.length > 0) {
        if(Array.isArray(data) && data.length > 0) {
            const dataAsObjects = data.map(instance => formatTabulatorSafeDataInstance(instance, headerNames));
            return dataAsObjects;
        }
    }
    return data;
}

function toUpperCase(value = '') {
    return value && String(value).toUpperCase();
}

function excelToMoment(value = '') {
    return isValueUndefined(value) ? null : moment(parseExcelDate(value));
}

/**
 * Input could be text or key so we want to convert it to the key
 * @param {string} val - The value to convert
 * @param {Array} options - The options to search
 * @returns {string} The option value or value if not found
 */
function toOptionValue(value, options) {
	if (!value) return '';
	let val = toSearchString(value)
	const option = options.find(opt => toSearchString(opt.text.toLowerCase()) === val || opt.key.toLowerCase() === val) 
	return option ? option.key : value;
}

/**
 * Convert a value to a yes or no value
 * @param {string} val - The value to convert
 * @returns {string} The yes or no value
 */
function toYesNo(val) {
	if (val === 0) return 'n'
	else if (val === 1) return 'y'
	else if(!val) return ''
	
	val = String(val).toLowerCase()
	if (['missing', 'blank'].includes(val)) return ''
	if (['true', 'y', 'yes'].includes(val)) return 'y'
	return 'n'
}

/**
 * Convert a value to a blank if undefined
 * @param {string} val - The value to convert
 * @returns {string} The blank value
 */
function toBlankIfUndefined(val) {
    return val ? val : '';
}

/**
 * Create an event
 * @param {ParticipationEvent | EmploymentEvent} entity - The entity to represneting the event type
 * @param {string} code - The event code
 * @param {moment} date - The event date
 * @param {string} comment - The event comment
 * @param {EVENT_SOURCE key} source - The event source
 * @returns {ParticipationEvent | EmploymentEvent} The event
 */
function toEvent (entity, code, date, comment, source) {
    return new entity({
        code,
        cmt: comment,
        ets: date.valueOf(),
        source: source
    });
}

/**
 * Removes whitespaces on each end, return undefined if the value is 'missing' or 'blank'
 * @param {string | number} val Value to clean
 * @returns {string | undefined} Cleaned value
*/
function toCleanString(val = '') {
    //if string trim
    if (typeof val === 'string') {
        val = val.trim();
    }
    if (val === '') return undefined;
	return val;
}

/**
 * Convert a value to an enum value
 * @param {string} val - The value to convert
 * @param {Enum} enumType - The enum type
 * @returns {string} The enum value or empty string if not found
 */
function toEnumValue(val, enumType) {
	return enumType.types[val] ? val.toString() : '';
}

export {
    formatStringByPattern,
    formatAmount,
    formatNumber,
    formatDate,
    formatPercentage,
    formatDateYYYYMMDD,
    formatTabulatorSafeData,
    formatTabulatorSafeDataInstance,
    toOptionValue,
    toEnumValue,
    toUpperCase,
    excelToMoment,
    toYesNo,
    toBlankIfUndefined,
    toEvent,
    toCleanString
}