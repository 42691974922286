import { Definition, Entity } from '../../framework/infra'
import { Employment, EmploymentStatus } from '../employment';
import Validations from './Validation';
import { ParticipationStatus } from '../membership';
import { Beneficiaries } from '../pension';
const { AMOUNT, BOOLEAN, NUMBER, STRING, CHOICE, DATE, SIN, YESNO } = Definition.types

//This is necessary for memory issues in reporting. We want to keep the least amount of
//data in memory which is why we pick and choose properties
export default class YearEndEmploymentSummary extends Entity {


    /**
     * Takes in a list of RemittanceDetails grouped by year and assigns it to the employment summary
     * @param {Object<string, RemittanceDetails>} groupedDetails 
     */
    assignFullContributionData(groupedDetails) {
        //get only the contribution total for each year with year being the key
        const contributionDetails = Object.entries(groupedDetails).map(([year, details]) => ({
            year: year,
            contributionTotal: details.contributionsTotals.total,
            adjustmentTotal: details.contributionAdjustmentsTotals.total,
            regTotal: details.contributionsTotals.reg,
            matTotal: details.contributionsTotals.mat,
            ltdTotal: details.contributionsTotals.ltd,
            slfTotal: details.contributionsTotals.slf,
            volTotal: details.contributionsTotals.vol,
            retroTotal: details.retroAdjustmentsTotal,
        }));
        this.contributionDetails = contributionDetails;
    }

    assignValidationResults(validation) {
        this.validation = validation;
    }

    assignLastEnd(detail) {
        this.lastSchedule =  detail.workSchedule;
        this.lastYtdCreditedService =  detail.ytdCreditedService;
        this.lastAnnualizedEarningsRegular = detail.annualizedEarningsRegular;
    }

    assignEnd(detail) {
        this.schedule =  detail.workSchedule;
        this.ytdCreditedService =  detail.ytdCreditedService;
        this.ytdEarnings = detail.ytdEarnings;
        this.ytdContributions = detail.ytdContributions;
        this.ytdRetroContributionsTotal = detail.allYearAdjustment.retro.totalDetail;
        this.pensionAdjustment = detail.pensionAdjustment;
        this.employmentPpStatusDesc = detail.employmentPpStatusDesc;
        this.employmentStatus = detail.employmentStatus;
        this.employmentStatusEffDate = detail.employmentStatusEffDate;
        this.ppStatus = detail.ppStatusEvent.status;
        this.ppStatusEventDesc = detail.ppStatusEvent.eventDesc;
        this.ppStatusEventEffectiveDate = detail.ppStatusEvent.effectiveDate;
        this.annualizedEarningsRegular = detail.annualizedEarningsRegular;
        this.ytdPriorAdjustment = detail.ytdPriorAdjustment;
        this.cmt = detail.cmt;
        this.employerCmt = detail.employerCmt;
        this.financialCmt = detail.financialCmt;
        this.reviewedCmt = detail.reviewedCmt;

        // Personal Data ER Initials (Terminations-CONFIRM sheet, column P)
        this.personalDataERInitialsCmt = detail.personalDataERInitialsCmt;
        // Status Confirmation (Terminations-CONFIRM sheet, column T)
        this.statusConfirmationCmt = detail.statusConfirmationCmt;
        // Status ER Initials (Terminations-CONFIRM sheet, column U)
        this.statusERInitialsCmt = detail.statusERInitialsCmt;
        // Membership termination date Confirmation (Terminations-CONFIRM sheet, Column AA)
        this.memtdConfirmationCmt = detail.memtdConfirmationCmt;
        // Membership termination date ER Initials (Terminations-CONFIRM sheet, Column AC)
        this.memtdERInitialsCmt = detail.memtdERInitialsCmt;
        // Marital Status ER Initials (Terminations-CONFIRM sheet, column AH)
        this.maritalStatusERInitialsCmt = detail.maritalStatusERInitialsCmt;
        // Total Pensionable Earnings ER Initials (Terminations-CONFIRM sheet, column AN)
        this.peERInitialsCmt = detail.peERInitialsCmt;
        // Total Contributions ER Initials (Terminations-CONFIRM sheet, Column AV)
        this.contERInitialsCmt = detail.contERInitialsCmt;
        // Total Credited Service (PH?) ER Initials (Terminations-CONFIRM sheet, Columns BB)
        this.phERInitialsCmt = detail.phERInitialsCmt;
        // Pensionable Earnings Validations: One time Confirmation (Terminations-CONFIRM sheet, Column BH)
        this.oneTimeConfirmationCmt = detail.oneTimeConfirmationCmt;
        // Pensionable Earnings Validations: Variation ER Initials (Terminations-CONFIRM sheet, Column BI)
        this.variationERInitialsCmt = detail.variationERInitialsCmt;

        this.yeValidated = detail.yeValidated;
        this.detailKey = detail.keyValue;
        this.yearActiveDays = detail.yearActiveDays;
        this.isOnLeaveOrPending = detail.isOnLeaveOrPending();
        this.lastWorkDay = detail.lastWorkDay  || '';
        this.lastServiceDay = detail.lastServiceDay;
        this.daysDifferential = detail.daysDifferential || 0;
        this.includeInMercerReport = !(detail.ppStatusEvent.status.isEligiblePeriod() || (detail.employmentStatus.isTransfer() && detail.hasNoFinancialData(true)));
    }

    /**
     * 
     * @param {*} employment The employment
     * @param {*} useDefaultSpouse Flag to get the employment's spouse for setting the marital status.
     * If true, the marital status will be Single if no spouse was set in the employment. If false, the marital status will be blank if no spouse was set in the employment.
     */
    assignEmployment(employment, useDefaultSpouse = true) {
        //employer
        this.code = employment.employer.code;
        this.mercerDiv = employment.employer.mercerDiv;

        //person
        this.sin = employment.person.sin;
        this.lastName = employment.person.lastName;
        this.middleName = employment.person.middleName;
        this.firstName = employment.person.firstName;
        this.dob = employment.person.dob;
        this.name = employment.person.name;
        this.address = employment.person.address?.desc;
        this.email = employment.person.emails?.first?.email;
        this.phone = employment.person.phones?.first?.number;
        this.gender = employment.person.gender;
        this.lng = employment.person.lng;
        this.spouseDob = employment.spouse.dob;
        this.spouseName = employment.spouse.nameFirstLast;
        this.spouseSin = employment.spouse.person.sin;
        this.maritalStatus = useDefaultSpouse ? employment.spouse.maritalStatus : employment.getSpouseNoDefault()?.maritalStatus;

        //employment
        this.noEmp = employment.noEmp;
        this.isCQ = employment.isCQ;
        this.isN = employment.isN;
        this.isTP = employment.isTP;
        this.hiredDate = employment.hiredDate;
        this.employmentType = employment.employmentType;
        this.workSchDesc = employment.workSch.key ? employment.workSch._desc : '';

        //participation
        this.joinDt = employment.participation.joinDt;
        this.beneficiaries = employment.participation.beneficiaries;
        this.beneficiariesDesc = employment.participation.beneficiaries.desc;
        this.mercerKey = employment.participation.mercerKey;
    }

    static definitions = {

        //Person
        code: { type: STRING, text: 'Code' },
        sin: { type: SIN, text: 'SIN' },
        lastName:  { type: STRING, text: 'Last Name' },
        middleName:  { type: STRING, text: 'Middle Name' },
        firstName:  { type: STRING, text: 'First Name' },
        dob: { type: DATE, text: 'Date of Birth' },
        name: { type: STRING, text: 'Name'  },
        address: { type: STRING, text: 'Address' },
        phone: { type: STRING, text: 'Phone' },
        email: { type: STRING, text: 'Email' },
        gender: { type: CHOICE, text: 'Gender' , options: [
            { key: '', text: '', text_fr: ''},
            { key: 'male', text: 'Male' },
            { key: 'female', text: 'Female' },
        ]},
        lng: {
            type: CHOICE, text: 'Language', options: [
            { key: 'en', text: 'English' },
            { key: 'fr', text: 'French' },
        ]},
        spouseName: { type: STRING, text: 'Spouse\'s Name'},
        spouseDob: { type: DATE, text: 'Spouse\'s DOB'},
        spouseSin: { type: SIN, text: 'Spouse\'s SIN'},
        maritalStatus: { type: STRING, text: 'Marital Status'},

        beneficiaries: { type: Beneficiaries, text: 'Beneficiaries'},
        beneficiariesDesc: { type: STRING, text: 'Beneficiaries'},

        //Employment
        noEmp: { type: STRING, text: 'Employee #' }, 
        isCQ: { type: YESNO, text: 'CPP/QPP' },
        isN: { type: YESNO, text: 'Native' },
        isTP: { type: YESNO, text: 'Tax Payer' },
        hiredDate: { type: DATE, text: 'Hired Date'},
        joinDt: { type: DATE, text: 'Join Date' },
        employmentType: { type: CHOICE, text: 'Employment Type', options: [
            { key: 'ft', text: 'Full-Time' },
            { key: 'pt', text: 'Part-Time' },
            { key: 'cs', text: 'Casual'},
        ]}, 
        detailKey: { type: STRING, text: "Remittance Detail Key" },
        workSchDesc: { type: STRING, text: 'Work Schedule' },

        //Detail
        lastSchedule:{ ref: Employment.definitions.workSch.ref, text: 'Yearly Work Schedule' },
        schedule:{ ref: Employment.definitions.workSch.ref, text: 'Yearly Work Schedule' },

        // lastYearlySchedule: { type: NUMBER, text: 'Yearly Work Schedule' },
        // yearlySchedule: { type: NUMBER, text: 'Yearly Work Schedule' },
        lastYtdCreditedService: {type: NUMBER, text: 'YTD Credited Service' },
        ytdCreditedService: { type: NUMBER, text: 'YTD Credited Service' },
        employmentPpStatusDesc: { type: STRING, text: 'Status' },
        employmentStatusEffDate: { type: DATE, text: 'Effective Date' },
        employmentStatus: { ref: EmploymentStatus, text: 'Employment Status' },

        lastWorkDay: { type: DATE, text: 'Last Work Date' },
        lastServiceDay: { type: DATE, text: 'Last Day of Service' },
        daysDifferential: { type: NUMBER, text: 'Days Differential' },

        includeInMercerReport: { type: BOOLEAN, text: 'Include in Mercer Report' },
        ppStatus: { ref: ParticipationStatus, text: 'Participation Status' },
        ppStatusEventDesc: { type: STRING, text: 'Status' },
        ppStatusEventEffectiveDate: { type: DATE, text: 'PP Effective Date' },
        ytdEarnings: { ref: require('../financial/earning/Earnings'), text: 'YTD' },
        pensionAdjustment: { type: AMOUNT, text: 'Pensionable Adjustment' },
        ytdContributions: { ref: require('../pension/contributions/Contributions'), text: 'YTD Contributions' },
        ytdRetroContributionsTotal: { type: AMOUNT, text: 'YTD Retro Contributions' },
        annualizedEarningsRegular: { type: AMOUNT,  text: 'Annualized Earnings Regular'},
        lastAnnualizedEarningsRegular: { type: AMOUNT,  text: 'Annualized Earnings Regular'},
        ytdPriorAdjustment: {type: NUMBER, text: 'YTD adjustment'},        
        cmt: { type: STRING, text: 'Comments'},     
        employerCmt: { type: STRING, text: 'Employer comments'},
        financialCmt: { type: STRING, text: 'Financial Comments'},
        reviewedCmt: { type: STRING, text: 'Admin Validation Comments'},

        // some of these fields defined as STRING might actually contain a date (columns P, U, AH, AN in Terminations sheet)
        // and the code of the report checks if it's a valid date and formats it as a date if possible
        // if the definition is changed to DATE, the custom code to format as date should be removed
        // See YEAR_END_TABS.TERMINATION.cellHardConfigs in src/templates/report-templates/yearEndTemplate.js
        // and YEUploadComments.initializeDetails() in src/views/pension/yearend/YEUploadComments.jsx

        // Personal Data ER Initials (Terminations-CONFIRM sheet, column P)
        personalDataERInitialsCmt: { type: STRING, text: 'Personal Data ER Initials'},
        // Status Confirmation (Terminations-CONFIRM sheet, column T)
        statusConfirmationCmt: { type: STRING, text: 'Status Confirmation'},
        // Status ER Initials (Terminations-CONFIRM sheet, column U)
        statusERInitialsCmt: { type: STRING, text: 'Status ER Initials'},
        // Membership termination date Confirmation (Terminations-CONFIRM sheet, Column AA)
        memtdConfirmationCmt: { type: STRING, text: 'MEMTD Confirmation'},
        // Membership termination date ER Initials (Terminations-CONFIRM sheet, Column AC)
        memtdERInitialsCmt: { type: STRING, text: 'MEMTD ER Initials'},
        // Marital Status ER Initials (Terminations-CONFIRM sheet, column AH)
        maritalStatusERInitialsCmt: { type: STRING, text: 'Marital Status ER Initials'},
        // Total Pensionable Earnings ER Initials (Terminations-CONFIRM sheet, column AN)
        peERInitialsCmt: { type: STRING, text: 'PE ER Initials'},
        // Total Contributions ER Initials (Terminations-CONFIRM sheet, Column AV)
        contERInitialsCmt: { type: STRING, text: 'Cont ER Initials'},
        // Total Credited Service (PH?) ER Initials (Terminations-CONFIRM sheet, Columns BB)
        phERInitialsCmt: { type: STRING, text: 'PH ER Initials'},
        // Pensionable Earnings Validations: One time Confirmation (Terminations-CONFIRM sheet, Column BH)
        oneTimeConfirmationCmt: { type: STRING, text: 'One time Confirmation'},
        // Pensionable Earnings Validations: Variation ER Initials (Terminations-CONFIRM sheet, Column BI)
        variationERInitialsCmt: { type: STRING, text: 'Variation ER Initials'},

        yeValidated: { type: BOOLEAN, text: 'Validated'},
        yearActiveDays: {abstract: true, type: Definition.types.NUMBER, text: 'Total Active Days'},

        //Employer
        mercerDiv: { type: STRING, text: 'DIV' },
        mercerKey: { type: STRING, text: 'Mercer Key' },

        //Report
        validation: { ref: Validations, text: 'Validations' },

        //Contribution
        contributionDetails: { type: Definition.types.UNDEFINED, text: 'Contribution Details' },
    }
}
