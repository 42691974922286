import { Persistence, Service } from '../../framework'

import { Adjustment, AdjustmentType, Earnings, Employment, Employments, Membership, Person, Remittance, RemittanceDetail, RemittanceStatus }  from "../../entities"
import { RemittanceBusiness }  from "../../business"
import { AdjustmentService, EmployerService, EmploymentService, RemittanceDetailService } from '..'
import { Period } from '../../framework/utils'
import { PersonService } from '../index'
import { MembershipService, ParticipationService } from '../membership'
import { EMPLOYMENT_SOURCE } from '../../entities/employment/Employment'
import uuid from 'uuid/v4';
import { EmploymentTriggers } from '../../entities/employment/EmploymentTriggers'
import { Definition } from '../../framework/infra'
import { EVENT_SOURCE } from '../../framework/infra/model/RefEvent'
import { EarningUploadPolicies } from '../../entities/pension/EarningUploadPolicies'

/**
 * @extends {Service}
 */
class RemittanceService extends Service {
  constructor() {
        super(Remittance, 'Remittance', 'remittance')
  }

  getClosedPeriods(employerId, options) {
    this.invalidateCache();
		return this.getBy('GetClosedPeriods', {id: employerId}, options).then(closedPeriodRemittances => 
            closedPeriodRemittances.map(remittance => remittance.period.value)
    );
  }

    getEmployerRemittances(employerId, options) {
        const periodsToShow = Period.getPeriods(Period.getLaunchPeriod().dec(1), Period.getCurrentPeriod(), true);
        const isLastPeriodYE = periodsToShow?.[periodsToShow.length - 1].yearEnd;
        return Promise.all((isLastPeriodYE ? periodsToShow : [...periodsToShow, Period.create().yearEndPeriod])
            .map(period => this.get(employerId + '_' + period.value, options)),
        ).then(rems => {
            return RemittanceBusiness.refreshBalances(this.toRefList(rems)) //maybe we should just refresh new ones by calling initRemittance
        })
    }

    getRemittancesForPeriods(yearFilter, employer, options) {
        const employerId = employer.id;
        const joinDate = employer.joinDt;
        return this.getBy( "GetRemittancesForPeriods", { employer: employerId, joinDate, yearFilter }, options, true );
    }

    async load(remittance, options = {}) {
        let employer = await EmployerService.get(remittance.employer.id);
        remittance.employer =employer;
        remittance.payments.forEach((payment) => (payment.employer = remittance.employer));

        return remittance
    }
    
	updateValidatedStatus(remittance) {
		return this.update(remittance, 'status')
	}

	updateRemittance(remittance) {
		if(remittance.status === RemittanceStatus.NEW) remittance.status = RemittanceStatus.STARTED;
		return this.update(remittance);
	}

	updateYECertification = (remittance) => {
		return this.update(remittance, "yeCertificationEvents");
	};

    validateEarnings(earnings) {
        for (let earning of earnings.all) {
            if (isNaN(earning.amount)) 
                throw new Error('invalidEarnings', {cause: earning.earningType.label});
            if (isNaN(earning.hours)) 
                throw new Error('invalidHours', {cause: earning.earningType.label});
        }
    }

    /** 
     * This method is used to remove the employment and participation events that are linked to the remittance
     * When we reset a remittance, we want to remove the employments and participation events that were created by the remittance
     * @param {*} remittance 
     */
    async removeLinkedEmployments(remittance){
        for (let detailIndex = 0; detailIndex < (remittance?.details?.all ?? []).length; detailIndex++) {
            const detail = remittance?.details?.all?.[detailIndex];
            const employment = detail.employment;
            const lastEventStatus = employment?.eventStatuses?.last;
            const participation = employment?.participation;

            // When the employment was create by rem and it's hiredEvent has a pointer to the remittance, delete the employment
            if(employment?.source === EMPLOYMENT_SOURCE.REMITTANCE.key && lastEventStatus.config.isHiredEvent && remittance.isRemittanceEvent(lastEventStatus)){
                // If the employment is MER, delete the MER event from the other employments
                if(employment.events.all.find(ev => ev.config.isMultipleEmployer)){
                    const employments = await EmploymentService.getParticipationEmployments(participation.keyValue);
                    await Promise.all(employments.filter(emp => emp.keyValue !== employment.keyValue).map(async emp => {
                        const merEvent = emp.events.all.find(ev => ev.config.isMultipleEmployer);
                        if(merEvent && remittance.isRemittanceEvent(merEvent)){
                            emp.deleteEvent(merEvent);
                            await EmploymentService.updateEmployment(emp);
                        }
                    }))
                }
                // Delete the employment by force (even with financial data)
                await EmploymentService.deleteEmptyEmployment(employment.keyValue, {forceDelete: true});
                // If the participation has only one employment and it's the one we just deleted, delete the participation
                if(participation.employments.length === 1 && participation.employments.first.keyValue === employment.keyValue && remittance.isRemittanceEvent(employment.participation.eventStatuses.first)){
                    await ParticipationService.deleteParticipation(participation);
                    const membership = participation.membership;
                    // If the membership only contain the deleted participation, delete the person
                    if(membership.participations.length === 1 && membership.participations.first.keyValue === participation.keyValue){
                        await PersonService.deletePerson(participation.person);
                    }
                }
                // Remove the detail from the remittance since we don't want it to be update
                remittance.details.splice(detailIndex, 1);
                detailIndex--;
            }
            
        }
    }

    /** 
     * Remove the remittance's details employment events with a pointer to the remittance, and participation events with a pointer to the remittance
     * @param {Remittance} remittance the remittance
     * @returns {Promise<void>} 
     */
    async removeLinkedEvents(remittance){
        for (let detailIndex = 0; detailIndex < (remittance?.details?.all ?? []).length; detailIndex++) {
            const detail = remittance?.details?.all?.[detailIndex];

            // remove linked employment events
            const empEvents = [...detail.employment?.events?.all?.filter(ev => remittance.isRemittanceEvent(ev))];
            if(empEvents.length){
                let hasRemovedEvents = false;
                for (let eventIndex = 0; eventIndex < empEvents.length; eventIndex++) {
                    const event = empEvents[eventIndex];
                    detail.employment?.deleteEvent(event);
                    hasRemovedEvents = true;
                }
                if(hasRemovedEvents){
                    await EmploymentService.update(detail.employment)
                }
            }

            // remove linked participation events
            const ppEvents = [...detail.employment?.participation?.events?.all?.filter(ev => remittance.isRemittanceEvent(ev))];
            if(ppEvents.length){
                let hasRemovedEvents = false;
                for (let eventIndex = 0; eventIndex < ppEvents.length; eventIndex++) {
                    const event = ppEvents[eventIndex];
                    detail.employment?.participation?.deleteEvent(event);
                    hasRemovedEvents = true;
                }
                if(hasRemovedEvents){
                    await ParticipationService.updateParticipation(detail.employment.participation)
                }
            }
        
        }
    }

    async getEarningUploadData(employer) {
        let data = await this.persistence.callApi("remittance_GetEarningUploadData", { employer: employer.id });
        let multiple = []
        let employments = await Promise.all(data.employments.map((employment) => {
            let [person, no] = employment.pp.split('_');
            let membership = data.memberships.find(x=>x.person === person);
            if (membership.participations.find(x=> x.no === no)?.employers?.length > 1) {
                multiple.push(employment.pp);
            }
            return EmploymentService.loadWithFullData(data.people, data.memberships, data.employments, [employer], employment.pp)
        }))
        return { multipleEmployerList: multiple, employments: new Employments(employments)};
    }

    /**
     * Look through the uploaded earnings and apply the policies to them if they pass their validation. Return a list of warnings for each policies that were applied.
     *  @param {RemittanceDetail} detail 
      * @param {{earnings: {isEmpty?: boolean}} | undefined} uploadedEarnings 
      * @param {{commit: boolean; openRemittance?: Remittance | undefined; eventsSource: string | undefined;} | undefined} options Options: - option to do a dry-run (not persist the changes in the db). - remittance ref (for the RTW event) - flag to set the event source
      * @return {warnings: string[]}
     */
    async applyEarningUploadPolicies(detail, uploadedEarnings, options)  {
        var warnings = [];
        const policies = Object.values(EarningUploadPolicies);
        for(let event of policies){
            if (event.validation(detail, uploadedEarnings)) {
                const beforeUpdateChecks = await event.action(detail, options);
                const warn = await event.warnings(options.commit, detail, beforeUpdateChecks);
                warnings.push(...warn);                 
            }
        }
        return warnings;
    }

    async inspectEarningUpload(remittance, uploadedEarnings) {

        const employments= await EmploymentService.getEmployerEmployments(remittance.employer.id);
        const employmentsBySin = employments.getLatestEmploymentPerPerson().getByPersonSIN();
        const relevantInPeriod = employments.getRelevantInPeriod(remittance.period);
        const inspectSummary = [];
        const processedEmployments = {};

        for ( var updErn of uploadedEarnings ) {
            const summary = new ResultDetail(updErn.rowId, `For ${updErn.sin} ${(updErn.firstName && updErn.lastName) ? `(${updErn.lastName}, ${updErn.firstName})` : ''}: `, employmentsBySin[updErn.sin]?.participation?.reorderedPpNo);
            
            try {

                //will throw error if earnings format is wrong
                this.validateEarnings(updErn.earnings);

                var relevantEmployments = relevantInPeriod[updErn.sin]
                //multiple employments means we need to setup an action to choose which pp to upload to.
                if (relevantEmployments?.length > 1) {
                    let warning = new Message('multiTarget');
                    warning.action.params.employments = relevantEmployments;
                    warning.action.params.defaultPpNo = employmentsBySin[updErn.sin].participation.no;
                    summary.warn(warning);
                }
                var employment = relevantEmployments?.length === 1 ? relevantEmployments[0] : employmentsBySin[updErn.sin];

                //Determine if the employment already appeared in the list
                const key = employment ? employment.keyValue : 'new';
                if (processedEmployments[updErn.sin]?.find(x => x === key)) {
                    throw new Error('duplicateSin'); 
                } else {
                    if (!processedEmployments[updErn.sin]) processedEmployments[updErn.sin] = [key];
                    else processedEmployments[updErn.sin].push(key);
                }
                //Entirely skip the processing if there are no earnings to upload
                if (!updErn.earnings.isEmpty) {
                    let empWarning;
                    //If there is at least one employment and we're not calculating differences
                    if (employment && !remittance.period.yearEnd) { 
                        //Need to do this to work with MERs
                        const lastEmployment = employmentsBySin[updErn.sin];
                        const eventsStatusInPeriod = lastEmployment.eventStatuses.getDuring(remittance.period.timestampAtPeriodStart, remittance.period.timestampAtPeriodEnd);
                        const isTerminated = eventsStatusInPeriod.find((e)=> e.status.isTerminated());
                        const isExpired = eventsStatusInPeriod.length > 0 && eventsStatusInPeriod[eventsStatusInPeriod.length-1].isExpired(remittance.period.timestampAtPeriodStart, employment.participation.jurisdictionCode, employment.participation.getActiveStatusEvent());
                        const isOpen = lastEmployment.participation.closeDate ? lastEmployment.participation.closeDate > remittance.period.timestampAtPeriodEnd : true;
                        const isMERandOpen = employment.participation.isMER && isOpen;
                        const ppEventsInPeriod = employment.participation.events.getDuring(remittance.period.timestampAtPeriodStart, remittance.period.timestampAtPeriodEnd)
                        const isEligible = ppEventsInPeriod[ppEventsInPeriod.length-1].status.isEligiblePeriod();
                        const isJanPeriod = remittance.period.moment.month() === Period.JAN_MONTH;
                        

                        if (isMERandOpen && (isTerminated || (isExpired && !lastEmployment.status.isActive()))) {
                            empWarning = new Message('termMer');
                        } else if (isTerminated) {
                            employment = null;
                            empWarning = new Message('termTer');
                        } else if (isExpired && !employment.status.isActive()) {
                            employment = null;
                            empWarning = new Message('termExp');
                        } else if (isEligible && !isJanPeriod) {
                            empWarning = new Message('eligWithEarnings');
                        }

                        const eventsWarnings = await this.applyEarningUploadPolicies(new RemittanceDetail({employment, remittance}), updErn, {commit: false, openRemittance: remittance});
                        for (let warning of eventsWarnings) {
                            summary.warn(new Message(warning));
                        }
                       
                    }

                    //create employment and person if they don't exist yet
                    if (!employment) {
                        var person = await PersonService.getPersonBySin(updErn.sin);
                        let personMembership, result;

                        if(!person) {
                            var newPerson = new Person({ firstName: updErn.firstName, lastName: updErn.lastName, sin: updErn.sin, id: uuid() }); 
                            var errors =  PersonService.validate(newPerson);
                            if (errors.length > 0) throw new Error('invalidPersonInfo', { cause: errors.join(', ') }); 
                            summary.warn(new Message('newPerson'));
                            personMembership = new Membership();
                            personMembership.person = newPerson;
                            person = newPerson;
                        }
                        
                        if (!personMembership) personMembership = await MembershipService.getMembership(person.keyValue);
                        employment = new Employment({ employer: remittance.employer });
                        employment.employer.plan = remittance.employer.plan;
                        employment.addEvent({code: 'hrdRem', ets: remittance.period.timestampAtPeriodStart, cmt: 'New employment created. Hired date set to first of the month of remittance upload.', guessed: true, source: EVENT_SOURCE.FILE.key }, {openRemittance: remittance});
                        employment.participation.membership = personMembership;
                        result = await EmploymentService.createEmployment(employment, EMPLOYMENT_SOURCE.REMITTANCE , {noCommit: true, openRemittance: remittance, eventsSource: EVENT_SOURCE.FILE.key});
                        employment = result.employment;
                        if (result.employment) {
                            if (empWarning) {
                                if (empWarning.action) {
                                    empWarning.action.params.creationMessage = result.warning;
                                    empWarning.action.params.isUpdate = !result.newEmploymentCreated;
                                }
                                
                                summary.warn(empWarning);
                            } else {
                                summary.warn(result.warning);
                            }
                            
                            if(!result.newEmploymentCreated && result.noAction && result.warningTypes?.includes("HIRE_DATE_IN_SUSTAINED_MEMBERSHIP")){
                                summary.warn(new Message('hiredDateInSustainedMembershipNoAdd'));
                            }
                            //could be that no new emp was created due to 60days
                            else if (!result.newEmploymentCreated) {
                                summary.warn(new Message('currenEmp'));
                            }
                        } 
                    } else if (empWarning) {
                        summary.warn(empWarning);
                    }

                } else {
                    summary.log(new Message('noEarnings').text);
                }

                const ppStatusDuringPeriod = employment?.participation?.eventStatuses?.getAt(remittance.period.timestampAtPeriodEnd);
                if (summary.isDirty && (!ppStatusDuringPeriod?.status?.isPotential() || summary.messages.find(message => Boolean(message.action)))) inspectSummary.push(summary);
            } catch (e) {
                console.debug(e);
                summary.error(new Message(e.message) ?? e.message, e.cause);
            }
        }

        //Need to do this in case we updated instances via create emplo
		PersonService.invalidateCache();
		EmploymentService.invalidateCache();
		MembershipService.invalidateCache();
		ParticipationService.invalidateCache();

        return inspectSummary;
    }

    async uploadEarnings(remittance, uploadedEarnings, uploadActions = []) {
        const employments = await EmploymentService.getEmployerEmployments(remittance.employer.id);
        const relevantInPeriod = employments.getRelevantInPeriod(remittance.period);
        const employmentsBySin = employments.getLatestEmploymentPerPerson().getByPersonSIN();
        const employerEarningTypes = remittance.employer.earningTypes;
        const uploadSummary = [];
        const processedEmployments = {};

        for ( var updErn of uploadedEarnings ) {

            const summary = new ResultDetail(updErn.rowId, `For ${updErn.sin} ${(updErn.firstName && updErn.lastName) ? `(${updErn.lastName}, ${updErn.firstName})` : ''}: `);

            try {
                
                const relatedDetail = uploadActions.find(x=>x.rowId === updErn.rowId);
                //default to latest, but check if ppNo is specified
                var relevantEmployments = relevantInPeriod[updErn.sin];
                var employment = relevantEmployments?.length === 1 ? relevantEmployments[0] : employmentsBySin[updErn.sin];
                
                //multi emps
                if (relatedDetail && relevantEmployments?.length > 1) { //get selected pp if any
                    employment = relevantEmployments.find(x=>x.participation.no === relatedDetail.targetPpNo);
                }


                //SIN duplicate check
                const key = employment ? employment.keyValue : 'new';
                if (processedEmployments[updErn.sin]?.find(x => x === key)) {
                     throw new Error('duplicateSin'); 
                } else {
                     if (!processedEmployments[updErn.sin]) processedEmployments[updErn.sin] = [key];
                     else processedEmployments[updErn.sin].push(key);
                }

                const skippedEmploymentCreation = relatedDetail?.messages?.find(x=> x.action instanceof ActionSkip)?.action?.resolution;

                if (!updErn.earnings.isEmpty) {
                    var yeEarningDifferences = new Earnings();
                    //Check if they are terminated or should be because of an expiration
                    if (employment && !skippedEmploymentCreation && employment.participation.ppNo === employmentsBySin[updErn.sin].participation.ppNo && !remittance.period.yearEnd) { 
                        //Only if target is the last employment and if its not for a yearEnd
                        const eventsStatusInPeriod = employment.eventStatuses.getDuring(remittance.period.timestampAtPeriodStart, remittance.period.timestampAtPeriodEnd);
                        const isTerminated = eventsStatusInPeriod.find((e)=> e.status.isTerminated());
                        const isExpired = eventsStatusInPeriod.length > 0 && eventsStatusInPeriod[eventsStatusInPeriod.length-1].isExpired(remittance.period.timestampAtPeriodStart, employment.participation.jurisdictionCode, employment.participation.getActiveStatusEvent());
                        const isOpen = employment.participation.closeDate ? employment.participation.closeDate > remittance.period.timestampAtPeriodEnd : true;
                        const isMERandOpen = employment.participation.isMER && isOpen;
                        if (!isMERandOpen && (isTerminated || (isExpired && !employment.status.isActive()))) {
                            if (isExpired && !employment.status.isActive()) {
                                await EmploymentTriggers.expireEmployment(employment, { ets: eventsStatusInPeriod[eventsStatusInPeriod.length-1].ets + 100, participation: employment.participation })
                                summary.warn(new Message('termExpCont'));
                            }
                            //mark as null to go through employment flow
                            employment = null;
                        }
                    }

                    let personMembership, result;

                    //create employment and person if they don't exist yet
                    const mustCreateEmployment = !employment;
                    if (mustCreateEmployment) {
                        let person = await PersonService.getPersonBySin(updErn.sin);

                        if(!person) {
                            person = new Person({ firstName: updErn.firstName, lastName: updErn.lastName, sin: updErn.sin});
                            result = await PersonService.create(person);
                            if(result.errors) throw new Error('invalidPerson', { cause: result.errors.join(', ') });
                            personMembership = new Membership();
                            personMembership.person = person;
                            summary.warn(new Message('newPersonCreated'));
                        }

                        if (!personMembership) personMembership = await MembershipService.getMembership(person.keyValue);
                        employment = new Employment({ employer: remittance.employer });
                        employment.employer.plan = remittance.employer.plan;
                        employment.addEvent({code: 'hrdRem', ets: remittance.period.timestampAtPeriodStart, cmt: 'New employment created. Hired date set to first of the month of remittance upload.', guessed: true, source: EVENT_SOURCE.FILE.key }, {openRemittance: remittance});
                        employment.participation.membership = personMembership;

                        result = await EmploymentService.createEmployment(employment, EMPLOYMENT_SOURCE.REMITTANCE, {eventsSource: EVENT_SOURCE.FILE.key, openRemittance: remittance});

                        if (result.employment) {
                            employment = result.employment;
                            if (result.warning !== '') {
                                summary.warn(result.warning);
                            }
                            if(!result.newEmploymentCreated && result.noAction && result.warningTypes?.includes("HIRE_DATE_IN_SUSTAINED_MEMBERSHIP")){
                                summary.log(new Message('hiredDateInSustainedMembershipNoAdded').text);
                                uploadSummary.push(summary);
                                continue;
                            }
                        }
                    }

                    var detail = remittance.details.find(x=>x.keysValues.participation === employment.keysValues.participation);
    
                    //create remittance detail if doesn't exist yet for employment
                    if (!detail && employment) {        
                        detail = new RemittanceDetail();
                        detail.remittance = remittance;
                        detail.participation = employment.participation;
                        detail.employment = employment;
                        remittance.details.push(detail);
                    } 

                    if (remittance.period.yearEnd) {
                        await RemittanceBusiness.calculateYEEarningDifferences( employerEarningTypes, detail, updErn, yeEarningDifferences );
                    }

                    detail.earnings = remittance.period.yearEnd ? yeEarningDifferences : updErn.earnings;
                    detail.cmt = updErn.cmt || detail.cmt;

                    // pass in the employment on the detail so changes get applied in the table on the remittance details page
                    const eventsWarnings = await this.applyEarningUploadPolicies(detail || new RemittanceDetail({employment: detail.employment, remittance}), updErn, {commit: true,  openRemittance: remittance});
                        for(let warning of eventsWarnings) {
                            summary.log(new Message(warning).text);
                        }

                    //Extra detail to be automatically displayed in summary table as a new column
                    summary.addExtraDetail({'pensionableEarning': {
                        config: { width: 100 },
                        text: 'Total Pensionable Earnings',
                        type: Definition.types.AMOUNT,
                        value: detail.earnings.pensionable
                    }})

                    summary.log(new Message('success').text);
                } else {
                    summary.log(new Message('noEarnings').text);
                } 
            } catch (e) {
                console.debug(e);
                summary.error(new Message(e.message) ?? e.message, e.cause);
            }

            uploadSummary.push(summary);
        }

        return uploadSummary;
    }

    handleCarryOverCredits = (remittance, remittances, isOnValidateClick = false) => {
        const nextPeriodRemittance = remittances.find(rem => rem.period.isSame(remittance.period.inc()));
        const adjustmentsWithLeftOverCredit = remittance.adjustments
            .filter(adjustment => isOnValidateClick 
          ? adjustment.leftOverCredit !== 0
          : adjustment.leftOverCredit !== 0 &&
                    nextPeriodRemittance.adjustments.find(adj => 
                        adj.type.key === adjustment.type.key
            )
    );
        const newCreditAdjustmentsForNextPeriod = adjustmentsWithLeftOverCredit.map(adjustment =>
        new Adjustment({
          employer: adjustment.employer,
          effDate: adjustment.effDate,
          endEffDate: adjustment.endEffDate,
                    type: AdjustmentType.types[`${adjustment.type.key}${!adjustment.type.isRemainingCreditType() ? 'R' : ''}`],
                    distributionContribution: [{ta: adjustment.type.config?.targetAccounts?.[0], am: adjustment.leftOverCredit}],
          cmt: adjustment.cmt,
                    remittance: remittances.find(rem => rem.period.value === adjustment.period.inc().value)?.keyValue,
                    participation: '',
        })
    );

    // if month is closed, create new adjustment for next period and update current period adjustment
    if (remittance.status.isValidated()
        && remittance.period.isBefore(Period.getCurrentPeriod())
        && adjustmentsWithLeftOverCredit.length !== 0
    ) {
        Promise.all([
        AdjustmentService.updateAll(newCreditAdjustmentsForNextPeriod),
        this.update(remittance),
        ]).then(() => {
                Promise.all(adjustmentsWithLeftOverCredit.map(adj => 
                    AdjustmentService.update(adj, '', { 
              previousAdjustmentKey: adj.keyValue,
                        totalAdjustments: adj.total
            })
                ))
        });
        } else {
            this.update(remittance);
        }
    }
}

//used to pick a participation from supplied list of employments
class ActionPickParticipation { 
    params = { employments: [] };
    resolution = null;
    text(action, detail) {
        return 'Earnings will be uploaded to participation #' + detail.targetPpNo;
    } 
}
//Used to skip employment creation
class ActionSkip { 
    params = { creationMessage: '', isUpdate: false }
    resolution = false;
    text() {
        return this.resolution === true ? 'Employment ' + (this.params.isUpdate ? 'update' : 'creation') +' skipped. Earnings will be uploaded to current employment.': this.params.creationMessage;
    } 
}


class Message { 
    constructor (key) {
        let item = this.messages[key]
        this.text = item?.text ?? key;
        if (item?.action) {
            this.action = new item.action();
        }
    }

    messages = {
        noEarnings: { text: 'No earnings to update.',},
        success: { text: 'Earnings uploaded successfully.' },
        currenEmp: { text: 'Earnings will be uploaded to the current employment.'},
        hiredDateInSustainedMembershipNoAdd: { text: 'Employment cannot be created automatically. No earnings will be uploaded.' },
        hiredDateInSustainedMembershipNoAdded: { text: 'Employment cannot be created automatically. No earnings have been uploaded.' },
        multiTarget: { text: 'Multiple employments found. Please specify a participation.', action: ActionPickParticipation },
        newPerson: { text: 'A new person and membership will be created.',},
        newEmployment: { text: 'A new employment will be created.',},
        termMer: { text: 'This person is working at multiple employers, the participation is open and they are terminated/expired in the target employment. Manual changes may be required.',},
        termTer: { text: 'This employment is terminated.', action: ActionSkip },
        termExp: { text: 'This employment should be terminated.', action: ActionSkip },
        termExpCont: { text: 'The previous employment has been terminated with "Leave Expired"',},
        eligWithEarnings: { text: 'Earnings during eligibility month. Guessed date flag on new first day of work event on first of the month.',},
        newPersonCreated: { text: 'New person created.',},
        invalidPP: { text: 'Invalid participation number PP#: :{attribute}', },
        invalidPersonInfo: { text: 'Missing required information to create person: :{attribute}',},
        invalidEarnings: { text: 'Invalid earning amount for :{attribute}',},
        invalidHours: { text: 'Invalid earning hour for :{attribute}',},
        invalidPerson: { text: 'Couldn\'t create person: :{attribute}',},
        invalidEmployment: { text: 'Couldn\'t create employment: :{attribute}',},
        duplicateSin: { text: 'Duplicate SIN found targeting the same employment.',},
        addRtwEvent: {text: 'Will add a RTW event'},
        addNrolfdayEvent: {text: 'Will add a NROL First Day event'},
        addNrolEvent: {text: 'Will add a NROL event on January 1st'},
        addParentalLeaveEvent: {text: 'Will add a Parental Leave event after 18 weeks of Maternity'},
        addedRtwEvent: {text: 'Added a RTW event'},
        addedNrolfdayEvent: {text: 'Added a NROL First Day event'},
        addedNrolEvent: {text: 'Added a NROL event on January 1st'},
        addedParentalLeaveEvent: {text: 'Added a Parental Leave event after 18 weeks of Maternity'},
    }
}

export const ActionList = {
    ActionPickParticipation,
    ActionSkip
}

export class ResultDetail {

    _defaultSeverity = 'i';
    
    constructor (rowId, defaultMessage = '', targetPpNo) {
        this.title = defaultMessage;
        this.id = uuid();
        this.messages = [];
        this.rowId = rowId;
        this.severity = this._defaultSeverity;
        this.targetPpNo = targetPpNo;
        
        this.extraSummaryDetails = [];
    }

    get isDirty () {
        return this.hasError || this.hasWarning || this.hasInfo;
    }

    get message () {
        return this.title + this.messages.map(x => `\n - ${!x.severity ? 'Info: ' : 
            x.severity === 'e' ? 'Error: ' : 
            x.severity === 'w' ? 'Warning: ' : ''
        } ${x.text}`)
    }

    warn (message, attribute) {
        this.log(message.text ?? message, attribute, 'w', message.action);
        this.hasWarning = true;
    }

    error (message, attribute) {
        this._reset();
        this.log(message.text ?? message, attribute, 'e', message.action);
        this.hasError = true;
    }

    log(text, attribute, severity, action) {
        let filledText = text.replace(':{attribute}', attribute);
        this.messages.push({ text: filledText, action: action, severity});
        this.severity = severity ?? this.severity;
        this.hasInfo = true;
    }

    //Adds an extra detail to be automatically displayed in summary table as a new column
    addExtraDetail(detail) {
        this.extraSummaryDetails = {...this.extraSummaryDetails, ...detail};
    }

    _reset() {
        this.messages = [];
        this.severity = this._defaultSeverity;
        this.hasError = this.hasWarning = this.hasInfo = false;
    }
}




const instance = new RemittanceService()
export default instance
